import { MassUnit } from 'utils/number-format';

export const MigrationOps = Object.freeze({
  delete: 'delete',
  nullify: 'nullify',
  useDefault: 'useDefault'
});

export interface MigrationStrategy {
  op: typeof MigrationOps[keyof typeof MigrationOps];
  value?: string;
}

export interface GuestTypeSettings {
  enabled: boolean;
  migrationStrategy: MigrationStrategy;
}

export interface GuestSettings {
  enableGuestRegistrationFlow?: boolean;
  guestTypes?: GuestTypeSettings;
  enableAutomaticGuestRegistrations?: boolean;
  automaticGuestRegistrationSchedule?: number[];
}

// deprecated, do not use
export interface RegistrationFrequency {
  [date: string]: number[];
}

// deprecated, do not use
export interface ExpectedWeeklyWaste {
  [date: string]: number;
}

export type FoodwastePeriod = 'day' | 'week' | 'month' | 'year' | 'fixed';

export interface StartSlot {
  from: string;
}

export interface FrequencyTarget {
  days: number[];
}

export interface FrequencyTargetTimeSlot extends FrequencyTarget, StartSlot {}

export interface WasteAmount {
  amount: number;
  unit: MassUnit;
  period: FoodwastePeriod;
}

export interface WasteAmountTimeSlot extends WasteAmount, StartSlot {}

export const WasteTargetKeys = [
  'expectedFoodwaste',
  'expectedFoodwastePerGuest',
  'perGuestBaseline',
  'perGuestStandard'
] as const;

export type WasteTargetKey = typeof WasteTargetKeys[number];
export const OverrideSettingsKeys = [
  ...WasteTargetKeys,
  'expectedFrequency',
  'expectedGuestFrequency',
  'lockRegistrationPoints',
  'enableLockedRegistrations',
  'allowSkipLastRegistrationPoint'
] as const;
export type OverrideSettingsKey = typeof OverrideSettingsKeys[number];
export type OverrideSettings = {
  [key in OverrideSettingsKey]?: {
    overridePrevious: boolean;
    enabled: boolean;
  };
};
export type LockedByAdmin = {
  [key in OverrideSettingsKey]?: boolean;
};

export type WasteTargetState = {
  [K in WasteTargetKey]: WasteAmountTimeSlot[];
};

interface Sound {
  id?: string;
  fileId?: string;
  url?: string;
  name?: string;
  enabled: boolean;
}

export type SavedFilterSelection = {
  name: string;
  accountIds: string[];
};

export type Unit = 'kg' | 'lt' | 'g';

export type Alarm = {
  enabled: boolean;
  executionTime: string;
  message: string;
  zone: string;
  recipients: AlarmRecipient[];
};

export type AlarmRecipient = {
  type: 'sms' | 'email';
  name: string;
  value: string;
};

export interface SavedSettings extends WasteTargetState {
  enableOffline?: boolean;
  alarms?: Alarm;
  currency: string;
  unit: Unit;
  database?: string; // deprecated?
  mandatory: string[];
  categoriesHidden: boolean; // deprecated
  lastUpload?: number;
  sound?: Sound;
  showDeactivatedAreas: boolean; // deprecated
  registrationsFrequency: RegistrationFrequency;
  expectedWeeklyWaste: ExpectedWeeklyWaste;
  bootstrapData: boolean;
  languageBootstrapData: string; // deprecated, use locale prop
  useAccountNickname: boolean;
  allowRegistrationsOnAnyPoint: boolean;
  enableRegistrationReason: boolean;
  guestTypes?: GuestTypeSettings;
  enableGuestRegistrationFlow?: boolean;
  canUseBluetooth: boolean;
  savedFilterSelections: SavedFilterSelection[];
  bootstrapTemplateId?: string;
  expectedFoodwaste: WasteAmountTimeSlot[];
  expectedFoodwastePerGuest: WasteAmountTimeSlot[];
  expectedFrequency: FrequencyTargetTimeSlot[];
  expectedGuestFrequency: FrequencyTargetTimeSlot[];
  perGuestBaseline: WasteAmountTimeSlot[];
  perGuestStandard: WasteAmountTimeSlot[];
  enableRegistrationComments?: boolean;
  enableLockedRegistrations?: number;
  allowSkipLastRegistrationPoint?: boolean;
  hasEsmileyScale?: boolean;
  // if user keeps getting scale not connected message on registration page,
  // we ask customer again if s/he has a scale, if this flag is false
  hasEsmileyScaleConfirmed?: boolean;
  // whether to execute tare on the scale after successful registration
  enableAutoTare?: boolean;
  overrideChildSettings?: OverrideSettings;
  lockRegistrationPoints: boolean;
  allowMultipleRegistrations: boolean;
  allowReasonRegistrationsOnly: boolean;
  enableAutomaticRegistrations?: boolean;
  automaticRegistrationSchedule?: number[];
  enableAutomaticGuestRegistrations?: boolean;
  automaticGuestRegistrationSchedule?: number[];
  reasonOnlyId?: string;
  id: string;
  locale: string; // this is the same locale as in haccp
}

export interface ExtraSettings {
  unitList: string[];
  currentFilter: string;
  isInitial: boolean;
  lockedByAdmin?: LockedByAdmin;
}

export type SettingsState = SavedSettings & ExtraSettings;

export enum SettingsActionTypes {
  UPDATE_SAVED = 'esmiley/settings/UPDATE_SAVED',
  FETCH = 'esmiley/settings/FETCH',
  UPDATE_EXTRA = 'esmiley/settings/UPDATE_EXRA',
  UPDATE_ACCOUNT_NAMES = 'esmiley/settings/UPDATE_ACCOUNT_NAMES',
  CHANGE_LOCALE = 'esmiley/settings/CHANGE_LOCALE'
}

type UpdateFilterAction = {
  type: typeof SettingsActionTypes.UPDATE_EXTRA;
  payload: { currentFilter: string };
};

type FetchAction = {
  type: typeof SettingsActionTypes.FETCH;
  payload: SavedSettings;
};

type UpdateAction = {
  type: typeof SettingsActionTypes.UPDATE_SAVED;
  payload: SavedSettings;
};

type ChangeLocaleAction = {
  type: typeof SettingsActionTypes.CHANGE_LOCALE;
  payload: string;
};

export type SettingsActions = UpdateFilterAction | FetchAction | UpdateAction | ChangeLocaleAction;
