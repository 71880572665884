import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import * as errorDispatch from 'redux/ducks/error';
import _pick from 'lodash/pick';
import {
  GuestSettings,
  SavedFilterSelection,
  SavedSettings,
  SettingsActions,
  SettingsActionTypes,
  SettingsState
} from 'redux/ducks/settings/types';
import * as guestRegistrationActions from 'redux/ducks/guestRegistrations';
import { AnyAction } from 'redux';
import { ErrorActions } from 'redux/ducks/error/types';
import { ThunkResult } from 'redux/types';
import { UserActions, UserActionTypes } from 'redux/ducks/user';
import { compare } from 'fast-json-patch';

export * from './types';
export * from './selectors';

const transfer = new DataTransfer();

const SAVED_FIELDS = [
  'enableAutoTare',
  'currency',
  'unit',
  'database',
  'mandatory',
  'categoriesHidden',
  'lastUpload',
  'showDeactivatedAreas',
  'bootstrapData',
  // deprecated, use language
  'languageBootstrapData',
  // deprecated
  'expectedWeeklyWaste',
  'registrationsFrequency',
  'sound',
  'useAccountNickname',
  'alarms',
  'allowRegistrationsOnAnyPoint',
  'enableRegistrationReason',
  'guestTypes',
  'enableGuestRegistrationFlow',
  'canUseBluetooth',
  'savedFilterSelections',
  'bootstrapTemplateId',
  'expectedFoodwaste',
  'expectedFoodwastePerGuest',
  'expectedFrequency',
  'expectedGuestFrequency',
  'perGuestBaseline',
  'perGuestStandard',
  'enableRegistrationComments',
  'hasEsmileyScale',
  'hasEsmileyScaleConfirmed',
  'overrideChildSettings',
  'allowMultipleRegistrations',
  'allowReasonRegistrationsOnly',
  'reasonOnlyId',
  'lockRegistrationPoints',
  'locale',
  'enableLockedRegistrations',
  'allowSkipLastRegistrationPoint',
  'enableAutomaticRegistrations',
  'automaticRegistrationSchedule',
  'enableAutomaticGuestRegistrations',
  'automaticGuestRegistrationSchedule'
];

export const initialState: SettingsState = {
  bootstrapData: undefined,
  languageBootstrapData: undefined,
  allowRegistrationsOnAnyPoint: false,
  enableRegistrationReason: false,
  // SAVED
  currency: 'DKK',
  unit: 'kg',
  database: '',
  mandatory: [],
  categoriesHidden: false,
  showDeactivatedAreas: false,
  sound: { enabled: true },
  canUseBluetooth: true,
  // EXTRA
  unitList: ['kg'],
  currentFilter: 'registrationPoints',
  isInitial: true,
  // deprecated, do not use
  registrationsFrequency: {},
  // deprecated, do not use
  expectedWeeklyWaste: {},
  useAccountNickname: false,
  alarms: undefined,
  savedFilterSelections: undefined,
  expectedFoodwaste: undefined,
  expectedFoodwastePerGuest: undefined,
  expectedFrequency: undefined,
  expectedGuestFrequency: undefined,
  perGuestBaseline: undefined,
  perGuestStandard: undefined,
  overrideChildSettings: undefined,
  allowMultipleRegistrations: false,
  allowReasonRegistrationsOnly: false,
  lockRegistrationPoints: false,
  allowSkipLastRegistrationPoint: false,
  id: undefined,
  locale: 'en' // could set to a cookie that's shared among *.esmiley.com domains(?)
};

export default function reducer(
  state: SettingsState = initialState,
  action: SettingsActions | UserActions
): SettingsState {
  switch (action.type) {
    case UserActionTypes.USER_LOADED: {
      const { settings } = action.payload;
      const hasNoSettings = !settings;
      const currency =
        hasNoSettings || !settings.currency ? initialState.currency : settings.currency;

      return {
        ...state,
        ...settings,
        currency,
        isInitial: false
      };
    }
    case SettingsActionTypes.UPDATE_SAVED: {
      const { payload } = action;
      return { ...state, ...payload, isInitial: false };
    }

    case SettingsActionTypes.FETCH: {
      const { payload } = action;
      // no settings returns [], otherwise object.
      // should fix this to be consistent and return empty object.
      // todo: fix the endpoint, eg create user/settings endpoint that returns
      // object or 404 or null, should not return []
      const hasNoSettings = Array.isArray(payload) && payload.length === 0;
      const currency = hasNoSettings ? initialState.currency : payload.currency;

      return {
        ...state,
        ...payload,
        currency,
        isInitial: false
      };
    }

    case SettingsActionTypes.UPDATE_EXTRA: {
      const { payload } = action;
      return { ...state, ...payload, isInitial: false };
    }

    case SettingsActionTypes.CHANGE_LOCALE: {
      return {
        ...state,
        locale: action.payload
      };
    }

    default:
      return state;
  }
}

export function updateFilter(filter: string): SettingsActions {
  return {
    type: SettingsActionTypes.UPDATE_EXTRA,
    payload: { currentFilter: filter }
  };
}

async function fetchSavedSettings(): Promise<SavedSettings> {
  const response = (await transfer.get(`/foodwaste/settings`)) as AxiosResponse<SavedSettings>;
  return response.data;
}

export function fetch(): ThunkResult<
  Promise<SettingsActions | ErrorActions>,
  SettingsActions | ErrorActions
> {
  return async (dispatch) => {
    try {
      const savedSettings = await fetchSavedSettings();
      return dispatch({
        type: SettingsActionTypes.FETCH,
        payload: savedSettings
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function update(
  data: Partial<SavedSettings>
): ThunkResult<Promise<SettingsActions | ErrorActions>, SettingsActions | ErrorActions> {
  return async (dispatch, getState) => {
    /*
     * Pick only saved settings
     */
    const settingsState = getState().settings;
    const currentSettings = _pick(settingsState, SAVED_FIELDS) as SavedSettings;
    const nextSettings = Object.assign(
      {},
      currentSettings,
      _pick(data, SAVED_FIELDS)
    ) as SavedSettings;

    const settingsPatchArray = compare({ current: currentSettings }, { current: nextSettings });

    try {
      const response = (await transfer.patch(
        `/foodwaste/settings/${settingsState.id}`,
        settingsPatchArray
      )) as AxiosResponse<SavedSettings>;

      return dispatch({
        type: SettingsActionTypes.UPDATE_SAVED,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

// TODO fix action type
export function setGuestSettings(settings: GuestSettings): ThunkResult<Promise<any>, AnyAction> {
  return async (dispatch) => {
    await dispatch(update(settings));
    return dispatch(guestRegistrationActions.reset());
  };
}

export function changeSavedSelections(
  savedFilterSelections: SavedFilterSelection[]
): ThunkResult<Promise<SettingsActions | ErrorActions>, SettingsActions> {
  return (dispatch) => {
    return dispatch(update({ savedFilterSelections }));
  };
}

export function changeLocale(
  locale: string
): ThunkResult<Promise<SettingsActions | ErrorActions>, SettingsActions | ErrorActions> {
  return async (dispatch, getState) => {
    const { settings, user } = getState();

    // optimistic update to avoid waiting for api response
    dispatch({
      type: SettingsActionTypes.CHANGE_LOCALE,
      payload: locale
    });

    // if bootstrapping, we cant make api requests yet
    if (!user?.subscription) {
      console.log('not updating with PATCH, settings is initial');
      return;
    }

    try {
      const patchBody = compare({ locale: settings.locale }, { locale });
      await transfer.patch(`/foodwaste/settings/${settings.id}/locale`, patchBody);
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}
