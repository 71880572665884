import HelpText from 'components/HelpText';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { CreateGuestType, GuestType } from 'redux/ducks/guestTypes/types';
import GuestTypeTable from './components/guestTypeTable';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch
} from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

// todo: should be iso dows 1-7
const DAYS_OF_WEEK = [1, 2, 3, 4, 5, 6, 0];

interface OwnProps {
  hasGuestTypesEnabled: boolean;
  hasGuestRegistrationFlowEnabled: boolean;
  guestTypes: GuestType[];
  hasAutomaticRegistrationsEnabled: boolean;
  automaticRegistrationSchedule: number[];
  onToggleGuestRegistrationFlow: (hasGuestFlowEnabled: boolean) => void;
  onToggleGuestTypes: (hasGuestTypesEnabled: boolean) => void;
  onToggleAutomaticRegistrations: (enabled: boolean) => void;
  onChangeAutomaticSchedule: (schedule: number[]) => void;
  onDelete: (guestType: GuestType) => void;
  onUpdate: (guestType: GuestType) => void;
  onCreate: (guestType: CreateGuestType) => void;
}

type GuestTypesProps = OwnProps;

export const GuestTypes: FunctionComponent<GuestTypesProps> = ({
  onToggleGuestRegistrationFlow,
  onToggleGuestTypes,
  hasGuestTypesEnabled,
  guestTypes,
  hasGuestRegistrationFlowEnabled,
  hasAutomaticRegistrationsEnabled,
  automaticRegistrationSchedule,
  onChangeAutomaticSchedule,
  onToggleAutomaticRegistrations,
  onDelete,
  onUpdate,
  onCreate
}) => {
  const intl = useIntl();
  const classes = useStyles({});

  return (
    <div className='setting'>
      <div className={classes.toggle}>
        <HelpText
          helpText={intl.formatMessage({ id: 'settings.enableGuestRegistrationFlowDescription' })}
        >
          &nbsp;
        </HelpText>
        <FormControlLabel
          control={
            <Switch
              color={'primary'}
              checked={hasGuestRegistrationFlowEnabled}
              style={{
                display: 'inline-block',
                width: 'auto'
              }}
              onChange={(e, isInputChecked) => {
                onToggleGuestRegistrationFlow(isInputChecked);
              }}
            />
          }
          label={intl.formatMessage({ id: 'settings.enableGuestRegistrationFlow' })}
        />
      </div>
      <div
        className={classNames(classes.fullWidth, {
          [classes.disabled]: !hasGuestRegistrationFlowEnabled
        })}
      >
        <div className={classes.toggle}>
          <HelpText helpText={intl.formatMessage({ id: 'settings.enableGuestTypesDescription' })}>
            &nbsp;
          </HelpText>
          <FormControlLabel
            control={
              <Switch
                disabled={!hasGuestRegistrationFlowEnabled || guestTypes.length === 0}
                color={'primary'}
                checked={hasGuestTypesEnabled}
                style={{
                  display: 'inline-block',
                  width: 'auto'
                }}
                onChange={(e, isInputChecked) => {
                  e.preventDefault();
                  if (!hasGuestRegistrationFlowEnabled || guestTypes.length === 0) {
                    return;
                  }
                  onToggleGuestTypes(isInputChecked);
                }}
              />
            }
            label={intl.formatMessage({ id: 'settings.enableGuestTypes' })}
          />
        </div>
        <div className='settingToggle settingAutomaticRegistration'>
          <div>
            <HelpText
              helpText={intl.formatMessage({ id: 'help.settings.enableAutomaticRegistrations' })}
            />
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  onChange={(e, isEnabled) => {
                    onToggleAutomaticRegistrations(isEnabled);
                  }}
                  checked={hasAutomaticRegistrationsEnabled ?? false}
                />
              }
              label={intl.formatMessage({
                id: 'settings.registrationPoints.enableAutomaticRegistrations'
              })}
            />
          </div>
          {hasAutomaticRegistrationsEnabled && (
            <FormControlLabel
              labelPlacement='start'
              control={
                <Select
                  style={{ marginLeft: '12px' }}
                  multiple
                  value={automaticRegistrationSchedule}
                  renderValue={(selected: string[]) =>
                    selected
                      .sort()
                      .map((dow) => moment.weekdays(dow))
                      .join(', ')
                  }
                  onChange={(e) => {
                    onChangeAutomaticSchedule(e.target.value as number[]);
                  }}
                >
                  {DAYS_OF_WEEK.map((dow) => (
                    <MenuItem value={dow} key={`automatic_schedule_${dow}`}>
                      <Checkbox
                        color='primary'
                        checked={automaticRegistrationSchedule.includes(dow)}
                      />
                      {moment.weekdays(dow)}
                    </MenuItem>
                  ))}
                </Select>
              }
              label={intl.formatMessage({
                id: 'settings.registrationPoints.automaticRegisrationSchedule'
              })}
            />
          )}
        </div>
        <GuestTypeTable
          guestTypes={guestTypes}
          onDelete={onDelete}
          onCreate={onCreate}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  toggle: {
    width: '100%'
  },
  disabled: {
    position: 'relative',
    opacity: 0.4,
    pointerEvents: 'none'
  },
  fullWidth: {
    width: '100%'
  }
});

export default GuestTypes;
